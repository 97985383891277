import React, { useCallback } from "react";
import { match } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Formik, Form } from "formik";
import { validate } from "../util/formikValidateJsonSchema";

import * as models from "../models";
import { formatError } from "../util/error";
import FormikAutoSave from "../util/formikAutoSave";
import { TextField, Snackbar, Icon,RadioButtonField } from "@amplication/design-system";
import { COLORS } from "./constants";
import { ColorSelectButton } from "../Components/ColorSelectButton";
import { useTracking } from "../util/analytics";
import { GET_APPLICATION } from "./ApplicationHome";
import "./ApplicationForm.scss";

type Props = {
  match: match<{ application: string }>;
};

type TData = {
  updateApp: models.App;
};

const FORM_SCHEMA = {
  required: ["name"],
  properties: {
    name: {
      type: "string",
      minLength: 2,
    },
    description: {
      type: "string",
    },
  },
};

const CLASS_NAME = "application-form";

function ApplicationForm({ match }: Props) {
  const applicationId = match.params.application;

  const { data, error } = useQuery<{
    app: models.App;
  }>(GET_APPLICATION, {
    variables: {
      id: applicationId,
    },
  });

  const { trackEvent } = useTracking();

  const [updateApp, { error: updateError }] = useMutation<TData>(UPDATE_APP);

  const handleSubmit = useCallback(
    (data) => {

      const { name, description, color , appColors } = data;

      trackEvent({
        eventName: "updateAppInfo",
      });
      updateApp({
        variables: {
          data: {
            name,
            description,
            color,
            appColors
          },
          appId: applicationId,
        },
      }).catch(console.error);
    },
    [updateApp, applicationId, trackEvent]
  );

  const handleColorChange = useCallback(
    (color: string) => {
      trackEvent({
        eventName: "updateAppColor",
      });
      updateApp({
        variables: {
          data: {
            color,
          },
          appId: applicationId,
        },
      }).catch(console.error);
    },
    [updateApp, applicationId, trackEvent]
  );

  const handleMenuDisplayChange = useCallback(
    (menuDisplayTarget: any) => {

      const menuDisplay =menuDisplayTarget.target.value
      trackEvent({
        eventName: "updateAppMenuDisplay",
      });
      updateApp({
        variables: {
          data: {
            menuDisplay
          },
          appId: applicationId,
        },
      }).catch(console.error);
    },
    [updateApp, applicationId, trackEvent]
  );
  const handleTemplateChange = useCallback(
    (e: any) => {

      const authTemplate =e.target.value
      trackEvent({
        eventName: "updateAuthTemplate",
      });
      updateApp({
        variables: {
          data: {
            authTemplate
          },
          appId: applicationId,
        },
      }).catch(console.error);
    },
    [updateApp, applicationId, trackEvent]
  );

  const errorMessage = formatError(error || updateError);
  return (
    <div className={CLASS_NAME}>
      {data?.app && (
        <>
          <Formik
            initialValues={{...data.app,menu: "horizontal" }}
            validate={(values: models.App) => validate(values, FORM_SCHEMA)}
          
            onSubmit={handleSubmit}
          >
            {(formik) => {
              return (
                <Form>
                  <h3 style={{fontWeight: 500}}>App Settings</h3>
                  <FormikAutoSave debounceMS={1000} />
                  <TextField name="name" label="Name" />
                  <TextField
                    autoComplete="off"
                    textarea
                    rows={3}
                    name="description"
                    label="Description"
                  />
                  <span class="amplication-menuMode">display menu mode</span>
                <div>
            <RadioButtonField
              name="menu"
              value="horizontal"
              label="horizontal"
              checked={data.app.menuDisplay=== "horizontal"}
              onChange={handleMenuDisplayChange}
              
            />
            <RadioButtonField
              name="menu"
              value="vertical"
              label="vertical"
              checked={data.app.menuDisplay === "vertical"}
              onChange={handleMenuDisplayChange}
            
            />
           </div>
           <div className="custom-color__container">
            <div>
                <h3>
                  <Icon icon="color" />
                  Primary Colors
                </h3>
                {data.app.appColors.primary.map((color:{label:string,value:string},index:number) => (
                            <TextField key={color.label} name={`appColors.primary.${index}.value`} label={color.label} />
                ))}
            </div>      
            <div>
              <h3>
                <Icon icon="color" />
                Secondary Colors
              </h3>
              {data.app.appColors.secondary.map((color:{label:string,value:string},index:number) => (
                          <TextField key={color.label} name={`appColors.secondary.${index}.value`} label={color.label} />
              ))}
            </div>
           </div>
           <div>
           <RadioButtonField
                      name="authTemplate"
                      value="template-1"
                      label="Template 1"
                      checked={data.app.authTemplate === "template-1"}
                      onChange={handleTemplateChange}            
            />
            <RadioButtonField
                      name="authTemplate"
                      value="template-2"
                      label="Template 2"
                      checked={data.app.authTemplate === "template-2"}
                      onChange={handleTemplateChange}          
            />       
           </div>    
        </Form>
              );
            }}


          </Formik>

          <div>
            <hr />
            <h3>
              <Icon icon="color" />
              App Color
            </h3>
            {COLORS.map((color) => (
              <ColorSelectButton
                color={color}
                key={color.value}
                onColorSelected={handleColorChange}
              />
            ))}
          </div>
        </>
      )}
      <Snackbar open={Boolean(error?.message || updateError?.message)} message={errorMessage} />
    </div>
  );
}

export default ApplicationForm;

const UPDATE_APP = gql`
  mutation updateApp($data: AppUpdateInput!, $appId: String!) {
    updateApp(data: $data, where: { id: $appId }) {
      id
      createdAt
      updatedAt
      name
      description
      color
      menuDisplay
      authTemplate
    }
  }
`;
