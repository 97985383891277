import React, { useCallback, useState,useEffect, useMemo } from "react";
import "./ServerLessComponent.css"; 
import { CreateAppFromExcelForm } from "./CreateAppFromExcelForm";
import { Icon, Snackbar, Toggle } from "@amplication/design-system";
import { Link } from "react-router-dom";
import { EnumImages, SvgThemeImage } from "../Components/SvgThemeImage";
import { gql, useMutation, useQuery } from "@apollo/client";
import { execSync } from "child_process";
import { REACT_APP_MY_HOSTNAME } from "../env";

import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { /* forEach, */ isEmpty } from "lodash";
import { useDropzone } from "react-dropzone";
import * as XLSX from "xlsx";
import {
  COMMON_FIELDS,
  EntitiesDiagramFormData,
} from "../EntitiesDiagram/EntitiesDiagram";
import * as models from "../models";
import { useTracking } from "../util/analytics";
import { formatError } from "../util/error";
import { GET_APPLICATIONS } from "../Workspaces/ApplicationList";
import "./CreateAppFromExcel.scss";
import { createServerLessApplication, sampleAppWithEntities, sampleAppWithoutEntities } from "./constants";
import ProgressBar from "../Components/ProgressBar";
import { upperFirst, camelCase } from "lodash";

/* type ColumnKey = {
  name: string;
  key: number;
}; */

/* type WorksheetRow = unknown[];
type WorksheetData = WorksheetRow[];
 */

export const CLASS_NAME = "create-app-from-excel";
export function ServerLessComponent() {
  type ImportField = {
  fieldName: string;
  originalFieldType: string;
  fieldType: models.EnumDataType;
  //sampleData: unknown[];
  importable: boolean;
  enumOptions: Array<Object>;
};

type TData = {
  createServerLessApp: models.App;
  createServerLessApplication: models.App;
};

  const [checked, setChecked] = React.useState(true);
  const [checkedGraphQl, setCheckedGraphQl] = React.useState(true);

  const handleChange = (event: any) => {
    setChecked(event.target.checked);
  };

  const handleChangeGraphQl = (event: any) => {
    setCheckedGraphQl(event.target.checked);
  };

  const [fileName, setFileName] = useState<string | null>(null);
  const [entities, setEntities] = useState<any[] | []>([]);
  const { data: appsData } = useQuery<{
    apps: Array<models.App>;
  }>(GET_APPLICATIONS);
  const [generalError, setGeneralError] = useState<Error | undefined>(
    undefined
  );

  const clearGeneralError = useCallback(() => {
    setGeneralError(undefined);
  }, [setGeneralError]);

  const { trackEvent } = useTracking();

  const history = useHistory();


  const appsExist = useMemo(() => {
    return appsData && !isEmpty(appsData.apps);
  }, [appsData]);


  // serverless Application


  const [createServerLessApp , { loading, data, error }] = useMutation<TData>(
    CREATE_SERVER_LESS_APP,
    {
      update(cache, { data }) {
        if (!data) return;
        const queryData = cache.readQuery<{ apps: Array<models.App> }>({
          query: GET_APPLICATIONS,
        });
        if (queryData === null) {
          return;
        }
        cache.writeQuery({
          query: GET_APPLICATIONS,
          data: {
            apps: queryData.apps.concat([data.createServerLessApplication]),
          },
        });
      },
    }
  );


  const clearSelectedFile = useCallback(() => {
    setFileName(null);
  }, [setFileName]);

  const initialValues = useMemo((): EntitiesDiagramFormData => {
    const fileNameWithoutExtension = fileName?.replace(/\.[^/.]+$/, "");
    const data: EntitiesDiagramFormData = {
      app: {
        name: fileNameWithoutExtension || "",
        description: fileNameWithoutExtension || "",
        isServerless : true
      },
      commitMessage: `Import schema from ${fileName}`,
      workSpaceId: "",
      useSupabase: true || false,
      useGraphQl: true || false,
      entities: entities,
    };

    return data;
  }, [fileName, entities]);


  const handleSubmit = useCallback(
    (data: EntitiesDiagramFormData) => {
      if (data.entities.find((entity) => isEmpty(entity.name))) {
        setGeneralError(new Error("Entity name cannot be empty"));
        return;
      } else {
        const names = data.entities.map((entity) => entity.name);

        const duplicate = names.filter(
          (name, index, arr) => arr.indexOf(name) !== index
        );

        if (!isEmpty(duplicate)) {
          setGeneralError(
            new Error(
              `Entity name must be unique. Duplicate names found - ${duplicate.join(
                ", "
              )} `
            )
          );
          return;
        }
      }

      trackEvent({
        eventName: "createAppFromFile",
        appName: data.app.name,
      });

      data.useSupabase = checked;
      data.useGraphQl = checkedGraphQl;
      data.app.isServerless = true;
      createServerLessApp({
        variables: {
          data: data,
        },
      }).catch(console.error);
    },
    [createServerLessApp, trackEvent, checked, checkedGraphQl]
  );

  const errorMessage = formatError(error) || formatError(generalError);


    const handleStartFromServerLess = useCallback(() => {
    trackEvent({
      eventName: "createServerLessApp",
    });

    createServerLessApplication.useSupabase = checked;
    createServerLessApplication.useGraphQl = checkedGraphQl;

    createServerLessApp({
      variables: { data: createServerLessApplication }
    }).catch(console.error);
  }, [checked, checkedGraphQl, createServerLessApplication, trackEvent]);

  

   useEffect(() => {
    if (data) {
      history.push(`/${data.createServerLessApplication.id}`);
    }
  }, [history, data]);



  return (
    <div className={CLASS_NAME}>
      <div className={`${CLASS_NAME}__layout`}>
        {loading ? (
          <div className={`${CLASS_NAME}__processing`}>
            <div className={`${CLASS_NAME}__processing__title`}>
              All set! We’re currently generating your app.
            </div>
            <div className={`${CLASS_NAME}__processing__message`}>
              It should only take a few seconds to finish. Don't go away!
            </div>

            <SvgThemeImage image={EnumImages.Generating} />
            <div className={`${CLASS_NAME}__processing__loader`}>
              <ProgressBar />
            </div>
            <div className={`${CLASS_NAME}__processing__tagline`}>
              For a full experience, connect with a GitHub repository and get a
              new Pull Request every time you make changes in your data model.
            </div>
          </div>
        ) : isEmpty(fileName) ? (
          <div className={`${CLASS_NAME}__select-file`}>
            {appsExist && (
              <Link to="/" className={`${CLASS_NAME}__back`}>
                <Icon icon="arrow_left" />
                Back
              </Link>
            )}
            <div className={`${CLASS_NAME}__header`}>
              <SvgThemeImage image={EnumImages.ImportExcel} />
              <h2>Start with schema from excel</h2>
              <div className={`${CLASS_NAME}__message`}>
                Start building your application from an existing schema. Just
                upload an excel or CSV file to import its schema, and generate
                your node.JS application source code
              </div>
            </div>

            {/* <div className={`${CLASS_NAME}__divider`}>or</div> */}
            <div className={`${CLASS_NAME}__other-options`}>
              {/* <Toggle
                label="Add Supabase"
                checked={checked}
                // onChange={handleChange}
              /> */}
            </div>
            <div className={`${CLASS_NAME}__other-options`}>
              {/* <Toggle
                label="GraphQL API"
                checked={checkedGraphQl}
                // onChange={handleChangeGraphQl}
              /> */}
            </div>
            <div className={`${CLASS_NAME}__other-options`}>
              <Link
                onClick={handleStartFromServerLess}
                className={`${CLASS_NAME}__other-options__option`}
                to={"#"}
              >
                <SvgThemeImage image={EnumImages.HonoApp} />
                <div>Generate Serverless Application (Hono/Cloudflare-Worker)</div>
                </Link>
            </div>
          </div>
        ) : (
          <CreateAppFromExcelForm
            fileName={fileName}
            loading={loading}
            onClearForm={clearSelectedFile}
            onSubmit={handleSubmit}
            initialValues={initialValues}
          />
        )}
        <Snackbar
          open={Boolean(error) || Boolean(generalError)}
          message={errorMessage}
          onClose={clearGeneralError}
        />
      </div>
    </div>
  );
}

const CREATE_SERVER_LESS_APP = gql`
  mutation createServerLessApplication($data: AppCreateWithEntitiesInput!) {
    createServerLessApplication(data: $data) {
      id
      name
      description
      isServerless
      builds(orderBy: { createdAt: Desc }, take: 1) {
        id
      }
    }
  }
`;