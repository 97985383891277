import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { isEmpty } from "lodash";
import { gql, useQuery, useMutation } from "@apollo/client";
import { formatError } from "../util/error";
import * as models from "../models";
import {
  SearchField,
  Snackbar,
  CircularProgress,
  ConfirmationDialog,
  Button,
  EnumButtonStyle,
} from "@amplication/design-system";
import NewRole from "./NewRole";
import InnerTabLink from "../Layout/InnerTabLink";
import "./RoleList.scss";

type TData = {
  appRoles: models.AppRole[];
};

const DATE_CREATED_FIELD = "createdAt";
const CLASS_NAME = "role-list";

type Props = {
  applicationId: string;
  selectFirst?: boolean;
};

const CONFIRM_BUTTON = { icon: "trash_2", label: "Delete" };
const DISMISS_BUTTON = { label: "Dismiss" };

export const RoleList = React.memo(
  ({ applicationId, selectFirst = false }: Props) => {
    const [searchPhrase, setSearchPhrase] = useState<string>("");
    const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
    const [selectedRoleId, setSelectedRoleId] = useState<string | null>(null);

    const handleSearchChange = useCallback(
      (value) => {
        setSearchPhrase(value);
      },
      [setSearchPhrase]
    );
    const history = useHistory();

    const { data, loading, error } = useQuery<TData>(GET_ROLES, {
      variables: {
        id: applicationId,
        orderBy: {
          [DATE_CREATED_FIELD]: models.SortOrder.Asc,
        },
        whereName:
          searchPhrase !== ""
            ? {
                contains: searchPhrase,
                mode: models.QueryMode.Insensitive,
              }
            : undefined,
      },
    });

    const [deleteRole] = useMutation(DELETE_ROLE, {
      onCompleted: () => {
        setConfirmDelete(false);
      },
      refetchQueries: [
        {
          query: GET_ROLES,
          variables: {
            id: applicationId,
            orderBy: {
              [DATE_CREATED_FIELD]: models.SortOrder.Asc,
            },
          },
        },
      ],
    });

    const handleDeleteClick = useCallback(
      (roleId: string) => {
        setSelectedRoleId(roleId);
        setConfirmDelete(true);
      },
      [setSelectedRoleId, setConfirmDelete]
    );

    const handleDismissDelete = useCallback(() => {
      setConfirmDelete(false);
    }, [setConfirmDelete]);

    const handleConfirmDelete = useCallback(() => {
      if (selectedRoleId) {
        deleteRole({
          variables: {
            roleId: selectedRoleId,
          },
        });
      }
    }, [selectedRoleId, deleteRole]);

    const errorMessage = formatError(error);

    const handleRoleChange = useCallback(
      (role: models.AppRole) => {
        const fieldUrl = `/${applicationId}/roles/${role.id}`;
        history.push(fieldUrl);
      },
      [history, applicationId]
    );

    useEffect(() => {
      if (selectFirst && data && !isEmpty(data.appRoles)) {
        console.log("role list effect - inside");
        const role = data.appRoles[0];
        const fieldUrl = `/${applicationId}/roles/${role.id}`;
        history.push(fieldUrl);
      }
    }, [data, selectFirst, applicationId, history]);
    return (
      <>
        <ConfirmationDialog
          isOpen={confirmDelete}
          title="Delete Role"
          confirmButton={CONFIRM_BUTTON}
          dismissButton={DISMISS_BUTTON}
          message="Are you sure you want to delete this role?"
          onConfirm={handleConfirmDelete}
          onDismiss={handleDismissDelete}
        />
        <div className={CLASS_NAME}>
        <SearchField
          label="search"
          placeholder="search"
          onChange={handleSearchChange}
        />
        <div className={`${CLASS_NAME}__header`}>
          {data?.appRoles.length} Roles
        </div>
        {loading && <CircularProgress />}
          {data?.appRoles?.map((role) => (
            <div key={role.id} style={{ position: 'relative' }} >
              <InnerTabLink
                icon="roles"
                to={`/${applicationId}/roles/${role.id}`}
              >
                <span>{role.displayName}</span>
              </InnerTabLink>
              <Button
                      style={{
                        position: 'absolute',
                        left: '85%',
                        top: '12%',
                      }}
              
                buttonStyle={EnumButtonStyle.Clear}
                icon="trash_2"
                onClick={() => handleDeleteClick(role.id)}
              />
            </div>
          ))}
        {data?.appRoles && (
          <NewRole onRoleAdd={handleRoleChange} applicationId={applicationId} />
        )}
        <Snackbar open={Boolean(error)} message={errorMessage} />
        </div>
      </>
    );
  }
);

const DELETE_ROLE = gql`
  mutation deleteRole($roleId: String!) {
    deleteAppRole(where: { id: $roleId }) {
      id
    }
  }
`;
export const GET_ROLES = gql`
  query getRoles(
    $id: String!
    $orderBy: AppRoleOrderByInput
    $whereName: StringFilter
  ) {
    appRoles(
      where: { app: { id: $id }, displayName: $whereName }
      orderBy: $orderBy
    ) {
      id
      name
      displayName
      description
    }
  }
`;
