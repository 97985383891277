import * as models from "../models";

const YELLOW = "#FFA800";
const RED = "#F64E60";
const PINK = "#F685A1";
const TURQUOISE = "#41CADD";
const GREEN = "#0BB783";
const BLUE = "#6993FF";

export const COLORS = [
  {
    value: YELLOW,
    label: "Yellow",
  },
  {
    value: RED,
    label: "Red",
  },
  {
    value: PINK,
    label: "Pink",
  },
  {
    value: TURQUOISE,
    label: "Turquoise",
  },
  {
    value: GREEN,
    label: "Green",
  },
  {
    value: BLUE,
    label: "Blue",
  },
];
export const APPCOLORS = [
  {
    value: RED,
    label: "primary",
  },
  {
    value: RED,
    label: "secondary",
  },
  {
    value: RED,
    label: "warning",
  },
];


export const COLOR_TO_NAME: {
  [key: string]: string;
} = {
  [YELLOW]: "yellow",
  [RED]: "red",
  [PINK]: "pink",
  [TURQUOISE]: "turquoise",
  [GREEN]: "green",
  [BLUE]: "blue",
};

// ADD DEFAULT ENTITY
export const sampleAppWithoutEntities: models.AppCreateWithEntitiesInput = {
  app: {
    name: "My app",
    description: "",
    color: GREEN,
    isServerless: false
  },
  commitMessage: "",
  entities: [],
  workSpaceId: "",
  useSupabase: true,
  useGraphQl: true,
};

// ADD DEFAULT ENTITY
export const createServerLessApplication: models.AppCreateServerLessInput = {
  app: {
    name: "ServerLess App",
    description: "This is a serverless application that uses cloudflare workers and hono framework.",
    color: BLUE,
    isServerless: true
  },
  commitMessage: "",
  entities: [],
  workSpaceId: "",
  useSupabase: true,
  useGraphQl: true,
};

export const sampleAppWithEntities: models.AppCreateWithEntitiesInput = {
  app: {
    name: "Sample app",
    description: "Sample application for e-commerce",
    color: YELLOW,
    isServerless: false
  },
  commitMessage: "",
  workSpaceId: "",
  useSupabase: true,
  useGraphQl: true,
  entities: [
    {
      name: "Orders",
      fields: [
        {
          name: "Quantity",
          dataType: models.EnumDataType.WholeNumber,
          isServerless: false
        },
        {
          name: "Discount",
          dataType: models.EnumDataType.DecimalNumber,
          isServerless: false
        },
        {
          name: "Total Price",
          dataType: models.EnumDataType.WholeNumber,
          isServerless: false
        },
      ],
      relationsToEntityIndex: [1, 3],
    },
    {
      name: "Customer",
      fields: [
        {
          name: "First Name",
          dataType: models.EnumDataType.SingleLineText,
          isServerless: false
        },
        {
          name: "Last Name",
          dataType: models.EnumDataType.SingleLineText,
          isServerless: false
        },
        {
          name: "Email",
          dataType: models.EnumDataType.Email,
          isServerless: false
        },
        {
          name: "Phone",
          dataType: models.EnumDataType.SingleLineText,
          isServerless: false
        },
      ],
      relationsToEntityIndex: [2],
    },
    {
      name: "Address",
      fields: [
        {
          name: "Address 1",
          dataType: models.EnumDataType.SingleLineText,
          isServerless: false
        },
        {
          name: "Address 2",
          dataType: models.EnumDataType.SingleLineText,
          isServerless: false
        },
        {
          name: "City",
          dataType: models.EnumDataType.SingleLineText,
          isServerless: false
        },
        {
          name: "State",
          dataType: models.EnumDataType.SingleLineText,
          isServerless: false
        },
        {
          name: "Zip",
          dataType: models.EnumDataType.WholeNumber,
          isServerless: false
        },
      ],
    },
    {
      name: "Product",
      fields: [
        {
          name: "Name",
          dataType: models.EnumDataType.SingleLineText,
          isServerless: false
        },
        {
          name: "Item Price",
          dataType: models.EnumDataType.DecimalNumber,
          isServerless: false
        },
        {
          name: "Description",
          dataType: models.EnumDataType.MultiLineText,
          isServerless: false
        },
      ],
    },
  ],
};
