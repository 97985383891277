import { PageContent } from "../Layout/WelcomePage";
import devfactoryLogo from "../assets/logo-devfactory-white.svg";
import recastLogo from "../assets/logo-recast.svg";

type PageContentOptions = {
  [key: string]: PageContent;
};

export const DEFAULT_PAGE_SOURCE = "default";

export const SIGN_IN_PAGE_CONTENT: PageContentOptions = {
  rc: {
    name: "Recast",
    title: "Convert your excel sheet into Node.js app",
    subTitle: "",
    logo: recastLogo,
    message: "Recast is built on and powered by Amplication.",
  },
  [DEFAULT_PAGE_SOURCE]: {
    name: "Amplication",
    title: "Instantly generate quality Node.js apps",
    subTitle: "Just code what matters.",
    logo: devfactoryLogo,
    message: "",
  },
};
