import React, { useCallback, useEffect, useMemo, useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useHistory, Link } from "react-router-dom";
import classNames from "classnames";
import { /* forEach, */ isEmpty } from "lodash";
import { useDropzone } from "react-dropzone";
import * as XLSX from "xlsx";
import { EnumImages, SvgThemeImage } from "../Components/SvgThemeImage";
import {
  COMMON_FIELDS,
  EntitiesDiagramFormData,
} from "../EntitiesDiagram/EntitiesDiagram";
import { Snackbar, Icon, Toggle } from "@amplication/design-system";
import * as models from "../models";
import { useTracking } from "../util/analytics";
import { formatError } from "../util/error";
import { GET_APPLICATIONS } from "../Workspaces/ApplicationList";
import "./CreateAppFromExcel.scss";
import { CreateAppFromExcelForm } from "./CreateAppFromExcelForm";
import { createServerLessApplication, sampleAppWithEntities, sampleAppWithoutEntities } from "./constants";
import ProgressBar from "../Components/ProgressBar";
import { upperFirst, camelCase } from "lodash";

/* type ColumnKey = {
  name: string;
  key: number;
}; */

/* type WorksheetRow = unknown[];
type WorksheetData = WorksheetRow[];
 */
type ImportField = {
  fieldName: string;
  originalFieldType: string;
  fieldType: models.EnumDataType;
  //sampleData: unknown[];
  importable: boolean;
  enumOptions: Array<Object>;
};

type TData = {
  createAppWithEntities: models.App;
  createServerLessApp: models.App;
};

export const CLASS_NAME = "create-app-from-excel";
//const MAX_SAMPLE_DATA = 3;

//let entities: any[] = [];
export function CreateAppFromExcel() {
  const [checked, setChecked] = React.useState(true);
  const [checkedGraphQl, setCheckedGraphQl] = React.useState(true);

  const handleChange = (event: any) => {
    setChecked(event.target.checked);
  };

  const handleChangeGraphQl = (event: any) => {
    setCheckedGraphQl(event.target.checked);
  };

  const [fileName, setFileName] = useState<string | null>(null);
  const [entities, setEntities] = useState<any[] | []>([]);
  const { data: appsData } = useQuery<{
    apps: Array<models.App>;
  }>(GET_APPLICATIONS);
  const [generalError, setGeneralError] = useState<Error | undefined>(
    undefined
  );

  const clearGeneralError = useCallback(() => {
    setGeneralError(undefined);
  }, [setGeneralError]);

  const { trackEvent } = useTracking();

  const history = useHistory();

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      let ent: any[] = [];
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      reader.onload = () => {
        setFileName(acceptedFiles[0].name);
        const wb = XLSX.read(reader.result, {
          type: rABS ? "binary" : "array",
        });

        for (let i = 0; i < wb.SheetNames.length; i++) {
          const worksheetName = wb.SheetNames[i];
          const ws = wb.Sheets[worksheetName];

          /* Convert array of arrays */
          const jsonData = XLSX.utils.sheet_to_json(ws, {
            header: 1,
            blankrows: false,
          });
          const entityFields: any[] = Object.values(jsonData);
          const entity = generatesheetKeys(entityFields);

          trackEvent({
            eventName: "uploadFileToImportSchema",
            appName: fileName,
          });
          if (!isEmpty(worksheetName)) {
            const res = buildImportList(entity, wb.SheetNames);
            ent.push({
              name: worksheetName,
              relationsToEntityIndex:
                res.relationTo.length > 0 ? res.relationTo : null,
              fields: res.fields.map((field) => ({
                name: field.fieldName,
                dataType: field.fieldType,
                enumOptions: field.enumOptions,
              })),
            });
          }
        }
        setEntities(ent);
      };

      // read file contents
      acceptedFiles.forEach((file) => reader.readAsBinaryString(file));
    },
    [trackEvent, fileName]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: SheetAcceptedFormats,
    maxFiles: 1,
    onDrop,
  });

  const appsExist = useMemo(() => {
    return appsData && !isEmpty(appsData.apps);
  }, [appsData]);


  const [createAppWithEntities, { loading, data, error }] = useMutation<TData>(
    CREATE_APP_WITH_ENTITIES,
    {
      update(cache, { data }) {
        if (!data) return;
        const queryData = cache.readQuery<{ apps: Array<models.App> }>({
          query: GET_APPLICATIONS,
        });
        if (queryData === null) {
          return;
        }
        cache.writeQuery({
          query: GET_APPLICATIONS,
          data: {
            apps: queryData.apps.concat([data.createAppWithEntities]),
          },
        });
      },
    }
  );

  // serverless Application

  const [createServerLessApp] = useMutation<TData>(
      CREATE_SERVER_LESS_APP,
      {
        update(cache, { data }) {
          if (!data) return;
          const queryData = cache.readQuery<{ apps: Array<models.App> }>({
            query: GET_APPLICATIONS,
          });
          if (queryData === null) {
            return;
          }
          cache.writeQuery({
            query: GET_APPLICATIONS,
            data: {
              apps: queryData.apps.concat([data.createServerLessApp]),
            },
          });
        },
      }
    );

  const clearSelectedFile = useCallback(() => {
    setFileName(null);
  }, [setFileName]);

  const initialValues = useMemo((): EntitiesDiagramFormData => {
    const fileNameWithoutExtension = fileName?.replace(/\.[^/.]+$/, "");
    const data: EntitiesDiagramFormData = {
      app: {
        name: fileNameWithoutExtension || "",
        description: fileNameWithoutExtension || "",
      },
      commitMessage: `Import schema from ${fileName}`,
      workSpaceId: "",
      useSupabase: true || false,
      useGraphQl: true || false,
      entities: entities,
    };

    return data;
  }, [fileName, entities]);

  const handleSubmit = useCallback(
    (data: EntitiesDiagramFormData) => {
      if (data.entities.find((entity) => isEmpty(entity.name))) {
        setGeneralError(new Error("Entity name cannot be empty"));
        return;
      } else {
        const names = data.entities.map((entity) => entity.name);

        const duplicate = names.filter(
          (name, index, arr) => arr.indexOf(name) !== index
        );

        if (!isEmpty(duplicate)) {
          setGeneralError(
            new Error(
              `Entity name must be unique. Duplicate names found - ${duplicate.join(
                ", "
              )} `
            )
          );
          return;
        }
      }

      trackEvent({
        eventName: "createAppFromFile",
        appName: data.app.name,
      });

      data.useSupabase = checked;
      data.useGraphQl = checkedGraphQl;

      createAppWithEntities({
        variables: {
          data: data,
        },
      }).catch(console.error);
    },
    [createAppWithEntities, trackEvent, checked, checkedGraphQl]
  );

  const errorMessage = formatError(error) || formatError(generalError);

  const handleStartFromSample = useCallback(() => {
    trackEvent({
      eventName: "createAppFromSample",
    });

    sampleAppWithEntities.useSupabase = checked;
    sampleAppWithEntities.useGraphQl = checkedGraphQl;
    createAppWithEntities({
      variables: { data: sampleAppWithEntities },
    }).catch(console.error);
  }, [checked, checkedGraphQl, createAppWithEntities, trackEvent]);

  const handleStartFromScratch = useCallback(() => {
    trackEvent({
      eventName: "createAppFromScratch",
    });

    sampleAppWithoutEntities.useSupabase = checked;
    sampleAppWithoutEntities.useGraphQl = checkedGraphQl;

    createAppWithEntities({
      variables: { data: sampleAppWithoutEntities },
    }).catch(console.error);
  }, [checked, checkedGraphQl, createAppWithEntities, trackEvent]);


    const handleStartFromServerLess = useCallback(() => {
    trackEvent({
      eventName: "createServerLessApp",
    });

    createServerLessApplication.useSupabase = checked;
    createServerLessApplication.useGraphQl = checkedGraphQl;

    createServerLessApp({
      variables: { data: createServerLessApplication }
    }).catch(console.error);
  }, [checked, checkedGraphQl, createServerLessApp, trackEvent]);

  

  useEffect(() => {
    // console.log(dataServerLessApp?.createServerLessApp.id)
    if (data) {
      const appId = data.createAppWithEntities.id;
      //const buildId = data.createAppuseMutationWithEntities.builds[0].id;
      history.push(`/${appId}/entities`);
    }
  }, [history, data]);

  const buildImportList = (entity: any[], entitiesList: any[]) => {
    const fields: ImportField[] = [];
    let relationTo: number[] = [] || null;
    for (const item of entity) {
      const fieldName = item.field;
      let originalFieldType = item.type;
      let enumOptions = item.options ? item.options : undefined;
      const isEntityType = entitiesList.indexOf(
        originalFieldType.replace("[]", "")
      );

      if (
        !COMMON_FIELDS.find(
          (commonField) =>
            commonField.name.toLowerCase() === fieldName.toLowerCase()
        )
      ) {
        /*  const sampleData = getColumnSampleData(
          originalFieldType,
          MAX_SAMPLE_DATA,
          fieldName
        ); */
        if (isEntityType > -1) {
          relationTo.push(isEntityType);
        } else {
          let fieldType: models.EnumDataType =
            models.EnumDataType.SingleLineText;
          if (
            originalFieldType.toLowerCase().includes("date") ||
            originalFieldType.toLowerCase().includes("time")
          ) {
            fieldType = models.EnumDataType.DateTime;
          } else if (originalFieldType.toLowerCase().includes("json")) {
            fieldType = models.EnumDataType.Json;
          } else if (
            ["bool", "boolean"].includes(originalFieldType.toLowerCase())
          ) {
            fieldType = models.EnumDataType.Boolean;
          } else if (
            ["string", "text", "varchar"].includes(
              originalFieldType.toLowerCase()
            )
          ) {
            fieldType = models.EnumDataType.SingleLineText;
          } else if (["paragraph"].includes(originalFieldType.toLowerCase())) {
            fieldType = models.EnumDataType.MultiLineText;
          } else if (
            ["enum", "enumeration"].includes(originalFieldType.toLowerCase())
          ) {
            fieldType = models.EnumDataType.OptionSet;
          } else {
            if (
              ["number", "int", "integer"].includes(
                originalFieldType.toLowerCase()
              )
            ) {
              fieldType = models.EnumDataType.WholeNumber;
            } else if (
              ["float", "large"].includes(originalFieldType.toLowerCase())
            ) {
              fieldType = models.EnumDataType.DecimalNumber;
            }
          }

          if (
            ["e-mail", "mail", "email"].includes(
              originalFieldType.toLowerCase()
            )
          ) {
            fieldType = models.EnumDataType.Email;
          } else if (
            ["pwd", "password"].includes(originalFieldType.toLowerCase())
          ) {
            fieldType = models.EnumDataType.Password;
          } else if (["role"].includes(originalFieldType.toLowerCase())) {
            fieldType = models.EnumDataType.Roles;
          }

          fields.push({
            fieldName,
            originalFieldType,
            fieldType,
            //sampleData,
            importable: true,
            enumOptions: enumOptions
              ? enumOptions.map((option: string) => {
                  return {
                    label:
                      option.charAt(0).toUpperCase() +
                      option.slice(1).toLocaleLowerCase(),
                    value: upperFirst(camelCase(option)),
                  };
                })
              : undefined,
          });
        }
      }
    }
    return { fields, relationTo };
  };
  return (
    <div className={CLASS_NAME}>
      <div className={`${CLASS_NAME}__layout`}>
        {loading ? (
          <div className={`${CLASS_NAME}__processing`}>
            <div className={`${CLASS_NAME}__processing__title`}>
              All set! We’re currently generating your app.
            </div>
            <div className={`${CLASS_NAME}__processing__message`}>
              It should only take a few seconds to finish. Don't go away!
            </div>

            <SvgThemeImage image={EnumImages.Generating} />
            <div className={`${CLASS_NAME}__processing__loader`}>
              <ProgressBar />
            </div>
            <div className={`${CLASS_NAME}__processing__tagline`}>
              For a full experience, connect with a GitHub repository and get a
              new Pull Request every time you make changes in your data model.
            </div>
          </div>
        ) : isEmpty(fileName) ? (
          <div className={`${CLASS_NAME}__select-file`}>
            {appsExist && (
              <Link to="/" className={`${CLASS_NAME}__back`}>
                <Icon icon="arrow_left" />
                Back
              </Link>
            )}
            <div className={`${CLASS_NAME}__header`}>
              <SvgThemeImage image={EnumImages.ImportExcel} />
              <h2>Start with schema from excel</h2>
              <div className={`${CLASS_NAME}__message`}>
                Start building your application from an existing schema. Just
                upload an excel or CSV file to import its schema, and generate
                your node.JS application source code
              </div>
            </div>

            <div
              {...getRootProps()}
              className={classNames(`${CLASS_NAME}__dropzone`, {
                [`${CLASS_NAME}__dropzone--active`]: isDragActive,
              })}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>Drop the file here ...</p>
              ) : (
                <p>Drag and drop a file here, or click to select a file</p>
              )}
            </div>
            <div className={`${CLASS_NAME}__divider`}>or</div>
            <div className={`${CLASS_NAME}__other-options`}>
              <Toggle
                label="Add Supabase"
                checked={checked}
                onChange={handleChange}
                
              />
            </div>
            <div className={`${CLASS_NAME}__other-options`}>
              <Toggle
                label="GraphQL API"
                checked={checkedGraphQl}
                onChange={handleChangeGraphQl}
              />
            </div>
            <div className={`${CLASS_NAME}__other-options`}>
              <Link
                onClick={handleStartFromScratch}
                className={`${CLASS_NAME}__other-options__option`}
                to={"#"}
              >
                <SvgThemeImage image={EnumImages.AddApp} />
                <div>Start from scratch</div>
              </Link>
              <Link
                // onClick={handleStartFromServerLess}
                className={`${CLASS_NAME}__other-options__option`}
                to={"/server-less"}
              >
                <SvgThemeImage image={EnumImages.HonoApp} />
                <div>Start With Hono ServerLess</div>
                </Link>
                <Link
                onClick={handleStartFromSample}
                className={`${CLASS_NAME}__other-options__option`}
                to={"#"}
                >
                <SvgThemeImage image={EnumImages.SampleApp} />
                <div>Start from a sample app</div>
              </Link>
            </div>
          </div>
        ) : (
          <CreateAppFromExcelForm
            fileName={fileName}
            loading={loading}
            onClearForm={clearSelectedFile}
            onSubmit={handleSubmit}
            initialValues={initialValues}
          />
        )}
        <Snackbar
          open={Boolean(error) || Boolean(generalError)}
          message={errorMessage}
          onClose={clearGeneralError}
        />
      </div>
    </div>
  );
}

/* list of supported file types */
// cSpell: disable
const SheetAcceptedFormats = [
  "xlsx",
  "xlsb",
  "xlsm",
  "xls",
  "xml",
  "csv",
  "txt",
  "ods",
  "fods",
  "uos",
  "sylk",
  "dif",
  "dbf",
  "prn",
  "qpw",
  "123",
  "wb*",
  "wq*",
  "html",
  "htm",
]
  .map((x) => `.${x}`)
  .join(",");
// cSpell: enable

const generatesheetKeys = (range: string[] | undefined): any[] => {
  if (undefined === range) return [];
  let keys = [];

  for (var i = 0; i < range.length; ++i) {
    keys[i] = { type: range[i][1], field: range[i][0] };
    if (range[i][2] && ["enum", "enumeration"].includes(range[i][1]))
      keys[i] = { ...keys[i], options: range[i][2].split("|") };
  }
  return keys;
};

/* function getColumnSampleData(
  data: WorksheetData,
  maxCount: number,
  columnKey: number
): unknown[] {
  const results: unknown[] = [];
  forEach(data, function (row) {
    if (results.length === maxCount) {
      return false;
    }
    if (undefined !== row[columnKey]) {
      results.push(row[columnKey]);
    }
  });
  return results;
} */

const CREATE_APP_WITH_ENTITIES = gql`
  mutation createAppWithEntities($data: AppCreateWithEntitiesInput!) {
    createAppWithEntities(data: $data) {
      id
      name
      description
      builds(orderBy: { createdAt: Desc }, take: 1) {
        id
      }
    }
  }
`;


const CREATE_SERVER_LESS_APP = gql`
  mutation createServerLessApplication($data: AppCreateWithEntitiesInput!) {
    createServerLessApplication(data: $data) {
      id
      name
      description
      builds(orderBy: { createdAt: Desc }, take: 1) {
        id
      }
    }
  }
`;












