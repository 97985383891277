import { gql, useQuery } from "@apollo/client";
import { EnumSourceControlService } from "../../../models";

type Props = {
  appId: string;
  sourceControlService: EnumSourceControlService;
};

export  default function useGitOrganisationName({ appId, sourceControlService }: Props) {

  const {data,loading} = useQuery(GET_ORGANISATION, {
    variables: { appId, sourceControlService },
  });
  console.log(data?.getOrganisationname)
  return { organisationname: data?.getOrganisationname[0], loadingOrganisationname: loading };
}


const GET_ORGANISATION = gql`
  query getOrganisationname(
    $appId: String!
    $sourceControlService: EnumSourceControlService!
  ) {
    getOrganisationname(appId: $appId, sourceControlService: $sourceControlService)
  }
`;
